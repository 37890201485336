<template>
  <div class='page-box'>
    <base-screen class="custom-screen">
      <a-form-model ref="formRef" layout="inline" :model="queryRef" @submit="handleSearch" @submit.native.prevent>
        <a-row>
        <a-form-model-item label="审核状态" prop="state">
          <a-select v-model="queryRef.state" placeholder='请选择' style="width: 200px">
            <a-select-option v-for='item in splitState' :key='item.value' :value="item.value">
              {{item.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
          <a-form-model-item class="screen-btns">
            <a-button type="primary" @click="handleSearch">查询</a-button>
            <a-button @click="handleReset">重置</a-button>
          </a-form-model-item>
        </a-row>
      </a-form-model>
    </base-screen>
<!--    <div class="btn-box">-->
<!--      <a-button type="primary" @click="exportFn" :loading="exporting"> 导出 </a-button>-->
<!--    </div>-->
    <div class='btn-box'>
      <a-button type='primary' @click='getSaleAddData'>创建指导销售</a-button>
    </div>
    <base-table :columns="columns" :dataSource="dataRef" :pagination="pageRtv" :rowKey="record => record.logId" :loading="loadingRef" @change="handleTableChange">
      <template #state="record">
        <span>{{splitState.find(x=>x.value === record).name}}</span>
      </template>
      <template #operation="_,record">
        <div class='table-operations '>
          <a-button type='link' @click='edit(record)' v-if='record.state == 0'>编辑</a-button>
          <a-button type='link' @click='saleDetail(record)'>售卖明细</a-button>
          <a-button type='link' @click='commitCheck(record)' v-if='record.state == 0'>提交审核</a-button>
          <a-button type='link' @click='deleteLog(record)' v-if='record.state == 0'>删除</a-button>
          <a-button type='link' @click='pass(record)' v-if='record.state == 1'>通过</a-button>
          <a-button type='link' @click='refused(record)' v-if='record.state == 1'>拒绝</a-button>
          <a-button type='link' @click='run(record)' v-if='record.state == 2'>执行指导销售</a-button>
        </div>
      </template>
    </base-table>
    <a-modal v-model='visibleAdd' :width="800" :title='isAdd?"创建指导销售":"编辑指导销售"' :confirm-loading='confirmLoading' @ok='handleSubmit' @cancel='handelCancel'>
      <a-form-model ref='formAddRef' :model="form" :rules="rules" :wrapper-col="{span:16}" :label-col="{span:8}">
        <a-row>
          <a-col :span='24'>
            <a-form-model-item :label-col="{span:4}" :wrapper-col="{span:20}"  label='指导销售名称' prop='name'>
              <a-input v-model="form.name" placeholder="请输入" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span='12'>
            <a-form-model-item label='等待分配总额/元' prop='allotWaitMoney'>
              <a-input-number :precision="2" v-model="form.allotWaitMoney" placeholder='请输入' style="width: 150px" :disabled="true"></a-input-number>
            </a-form-model-item>
          </a-col>
          <a-col :span='12'>
            <a-form-model-item label='系统显示单价' prop='priceShow'>
              <a-input-number :precision="2" v-model="form.priceShow" placeholder='请输入' style="width: 150px" :disabled="true"></a-input-number>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span='24'>
            <a-form-model-item :label-col="{span:4}" :wrapper-col="{span:20}"  label='通知内容' prop='content'>
              <a-textarea v-model="form.content" placeholder="请输入" :rows="4" />
              <div style="color: red">审核通过后，系统会根据通知内容自动发送通知给移动端</div>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span='24'>
            <a-form-model-item :label-col="{span:6}" :wrapper-col="{span:18}"  label='本次指导销售总股数' prop='saleAsTotal'>
              <a-input v-model="form.saleAsTotal" placeholder="请输入" :disabled="true"/>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>
    <a-modal
      v-model="visibleDetail"
      :footer="null"
      :keyboard="false"
      :mask-closable="false"
      width="75%"
      title="售卖明细"
    >
      <page-sale-detail
        :key="key"
        :logId="logId"
        :state="state"
        @close="visibleDetail = false"
        @success="getTableData"
      />
    </a-modal>
  </div>
</template>

<script>
import { onMounted, ref, watch } from '@vue/composition-api'
import { shop } from '@/api'
import { splitState } from '../../utils/constants'
import PageSaleDetail from './PageSaleDetail'
const columns = [
  {
    title: '指导销售名称',
    dataIndex: 'name',
  },
  {
    title: '等待分配金额',
    dataIndex: 'allotWaitMoney',
  },
  {
    title: '显示单价',
    dataIndex: 'priceShow',
  },
  {
    title: '本次指导销售总股数',
    dataIndex: 'saleAsTotal',
  },
  {
    title: '状态',
    dataIndex: 'state',
    scopedSlots: { customRender: 'state' },
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
  },
]
export default {
  name: 'PageSale',
  components: {
    PageSaleDetail,
  },
  setup (props, { root }) {
    const formRef = ref(null)
    const formAddRef = ref(null)
    const rules = ref({
      name: [
        { required: true, message: '请输入', trigger: 'change' },
      ],
      content: [
        { required: true, message: '请输入', trigger: 'change' },
      ],
    })
    const key = ref(0)
    const logId = ref(null)
    const state = ref(null)
    const queryRef = ref({
      state: undefined,
    })
    const isAdd = ref(false)
    const confirmLoading = ref(false)
    const visibleAdd = ref(false)
    const visibleDetail = ref(false)
    watch(visibleDetail, value => {
      if (!value) key.value++
    })
    const visibleCheck = ref(false)
    const dataRef = ref([])
    const pageRtv = ref({
      current: 1,
      size: 10,
      total: 0,
    })
    const form = ref({
      name: undefined,
      allotWaitMoney: undefined,
      priceShow: undefined,
      saleAsTotal: 0,
      content: undefined,
      state: 0,
    })
    const loadingRef = ref(false)
    onMounted(() => {
      Promise.all([getTableData()])
    })
    async function getTableData () {
      loadingRef.value = true
      dataRef.value = []
      const { data, page } = await shop.getSalePage({ ...queryRef.value, ...pageRtv.value })
      loadingRef.value = false

      dataRef.value = data || []
      pageRtv.value.total = page.total
    }

    function handleSearch () {
      pageRtv.value.current = 1
      getTableData()
    }

    async function handleReset () {
      formRef.value.resetFields()
      await root.$nextTick()
      handleSearch()
    }

    function handleTableChange ({ current }) {
      pageRtv.value.current = current
      getTableData()
    }

    async function getSaleAddData () {
      const { data, msg, code } = await shop.getSaleAddData()
      if (code === '00000') {
        form.value = data
        isAdd.value = true
        visibleAdd.value = true
      } else {
        root.$message.error(msg || '获取数据失败')
      }
    }
    async function handleSubmit () {
      formAddRef.value.validate((v) => {
        if (v) {
          root.$confirm({
            title: '操作提示',
            content: '确认提交吗？',
            onOk: async () => {
              submit()
            }
          })
        }
      })
    }
    async function submit () {
      confirmLoading.value = true
      const { msg, code } = await shop.saveSale(form.value)
      confirmLoading.value = false
      if (code === '00000') {
        root.$message.success('提交成功')
        handelCancel()
        getTableData()
      } else {
        root.$message.error(msg || '提交失败')
      }
    }
    async function handelCancel () {
      formAddRef.value.resetFields()
      visibleAdd.value = false
    }
    async function saleDetail (record) {
      logId.value = record.logId
      state.value = record.state
      visibleDetail.value = true
    }
    async function edit (record) {
      form.value = record
      visibleAdd.value = true
      isAdd.value = false
    }
    async function commitCheck (record) {
      root.$confirm({
        title: '操作提示',
        content: '确认提交审核吗？',
        onOk: async () => {
          const { code, msg } = await shop.commitCheckSale({
            logId: record.logId
          })
          if (code === '00000') {
            root.$message.success('操作成功')
            getTableData()
          } else {
            root.$message.error(msg || '操作失败')
          }
        }
      })
    }
    async function deleteLog (record) {
      root.$confirm({
        title: '操作提示',
        content: '确认刪除吗？',
        onOk: async () => {
          const { code, msg } = await shop.deleteSale({
            logId: record.logId
          })
          if (code === '00000') {
            root.$message.success('操作成功')
            getTableData()
          } else {
            root.$message.error(msg || '操作失败')
          }
        }
      })
    }
    async function pass (record) {
      root.$confirm({
        title: '操作提示',
        content: '确认审核通过吗？',
        onOk: async () => {
          const { code, msg } = await shop.passSale({
            logId: record.logId
          })
          if (code === '00000') {
            root.$message.success('操作成功')
            getTableData()
          } else {
            root.$message.error(msg || '操作失败')
          }
        }
      })
    }
    async function refused (record) {
      root.$confirm({
        title: '操作提示',
        content: '确认拒绝审核吗？',
        onOk: async () => {
          const { code, msg } = await shop.refusedSale({
            logId: record.logId
          })
          if (code === '00000') {
            root.$message.success('操作成功')
            getTableData()
          } else {
            root.$message.error(msg || '操作失败')
          }
        }
      })
    }
    async function run (record) {
      root.$confirm({
        title: '操作提示',
        content: '确认执行指导销售吗？',
        onOk: async () => {
          const { code, msg } = await shop.runSale({
            logId: record.logId
          })
          if (code === '00000') {
            root.$message.success('操作成功')
            getTableData()
          } else {
            root.$message.error(msg || '操作失败')
          }
        }
      })
    }

    return {
      queryRef,
      formRef,
      formAddRef,
      handleTableChange,
      handleReset,
      handleSearch,
      key,
      logId,
      state,
      isAdd,
      confirmLoading,
      handleSubmit,
      handelCancel,
      visibleAdd,
      visibleDetail,
      visibleCheck,
      columns,
      rules,
      form,
      splitState,
      loadingRef,
      dataRef,
      pageRtv,
      getTableData,
      getSaleAddData,
      saleDetail,
      edit,
      commitCheck,
      deleteLog,
      pass,
      refused,
      run,
    }
  },
}
</script>

<style lang="less" scoped>
@label-width: 84px;
.custom-screen {
  ::v-deep .solt-div {
    padding: 10px 0 0;
  }
  ::v-deep .ant-form-item-label {
    width: @label-width;
  }
}
.screen-btns {
  margin-left: @label-width;
}
.btn-date-shortcut {
  margin: 4px 12px 0 0;
  &:last-child {
    margin-right: 0;
  }
}
.record-list {
  margin-bottom: 32px;
  .record-item {
    margin-bottom: 8px;
    &:last-child {
      margin: 0;
    }
  }
}
</style>
